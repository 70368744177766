<template>
  <div class="home">
    <HelloWorld msg="Welcome to Your Vue.js App" />
    <material-dialog
      v-model="dialog"
      title="新增公版版本"
      topDivider
      bottomDivider
      max-width="500"
    >
      <material-select prependTitle="引用資料" :items="[1, 2, 3]">
        <template #append-outer>
          <v-btn> test </v-btn>
        </template>
      </material-select>
      Let Google help apps determine location. This means sending anonymous
      location data to Google, even when no apps are running Let Google help
      apps determine location. This means sending anonymous location data to
      Google, even when no apps are running
      <template #actions>
        <v-spacer></v-spacer>
        <btn-cancel @click="dialog = false">取消</btn-cancel>
        <v-btn color="primary" depressed @click="dialog = false"> 確定 </v-btn>
      </template>
    </material-dialog>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "./Home.vue";

export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
