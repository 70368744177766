<template>
  <v-container fluid>
    <h1 v-show="showTabBar" class="text-h1">首頁</h1>
    <v-btn v-show="showTabBar" @click="login()">登入</v-btn>
    <v-tabs
      class="mb-4"
      v-show="showTabBar"
      backgroundColor="transparent"
      v-model="tab"
    >
      <v-tab class="pa-0 mr-4" to="/home/to-sign-off">待簽核</v-tab>
      <v-tab class="pa-0" to="/home/to-do">待辦事項</v-tab>
    </v-tabs>
    <router-view></router-view>
  </v-container>
</template>
<script>
import axios from "axios";
// import {apiLogin} from '../../api/login.js'
var host_url = process.env.VUE_APP_HOST_URL;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
export default {
  data() {
    return {
      tab: "/home/to-sign-off",
    };
  },
  computed: {
    showTabBar() {
      if (this.$route.params?.type) return false;
      else return true;
    },
  },
  mounted() {},
  methods: {
    async login() {
      console.log("here");
      try {
        let post_data = {
          system: "ro",
          ssoToken: "Token received from EIP AMS redirect request parameter",
        };
        console.log(host_url);
        axios.post(host_url + "/auth/sso", post_data).then((response) => {
          console.log(response);
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("roles", response.data.roles);
          localStorage.setItem("fullName", response.data.fullName);
        });

        // let res = await apiLogin(post_data)
        // console.log(res)
      } catch (error) {}
    },
  },
};
</script>
<style scoped lang="scss"></style>
